import axios from "axios";
import IndexedDbFunctions from "@/assets/js/indexedDbFunctions";

export const actions = {
  listGet({commit, getters, state}, {resource, apiRoute, filterQuery}) {
    let meta = {
      counter: 0,
      complete: 0,
      emptyResult: true,
      data: [],
      [`complete${resource.charAt(0).toUpperCase()}${resource.slice(1)}`]: 0,
      loading: true,
      offset: 0,
      limit: 20,
      selected: {}
    };
    const resourcePlural = `${resource}s`;
    if (state.lists[resourcePlural].offset) {
      meta.offset = state.lists[resourcePlural].offset;
    }
    if (state.lists[resourcePlural].limit) {
      meta.limit = state.lists[resourcePlural].limit;
    }
    const headers = getters.getAuthorization;
    let timestamp = Date.now();
    axios
      .get(
        getters.getApiUrl +
        apiRoute +
        "/search?offset=" +
        meta.offset +
        "&limit=" +
        meta.limit +
        filterQuery+"&ts="+timestamp,
        {headers}
      )
      .then((response) => {
        meta.data = response.data.result.data;

        // Use the new mutation to initialize the 'selected' state
        commit('initializeSelectedState', resourcePlural);

        meta.complete = response.data.result.complete;
        meta[`complete${resource.charAt(0).toUpperCase()}${resource.slice(1)}`] = meta.complete; // Set dynamic variable name
        meta.loading = false;
        meta.counter = response.data.result.counter;
        if (!meta.counter) {
          meta.emptyResult = true;
        }
        commit(`${resource}Set`, meta); // Generate dynamic commit name
      });
  },

  async loadConfig({getters, commit, state}, config) {
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    let getUrl = getters.getApiUrl + "config/" + config.type;
    if (config.subType !== undefined) {
      getUrl = getUrl + "/" + config.subType;
    }
    getUrl = getUrl + "?limit=0";
    await axios.get(getUrl, {headers}).then(async (response) => {
      let shortResponse = response.data.result;
      commit("setConfigLoader", {loadPercent: config.percent, loadText: config.type, loadMessage: config.message});
      let key = config.type;
      if (config.subType !== undefined) {
        key = key + "_" + config.subType;
      }
      commit("setConfig", {key: key, data: shortResponse.data});
      await IndexedDbFunctions.addObject(state.dbName, state.dbVersion, 'config', {
        id: key,
        data: shortResponse.data
      });
    }).catch(function () {
    });
  },


}
