import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";

import Signin from "@/routing/core/Login/Signin.vue";

// core imports
import store from "../store/index"

import Tasks from "@/routing/core/Tasks/Tasks.vue";

import FilterTasks from "@/filter/Tasks";

import Documents from "@/routing/core/Documents/Documents.vue";

import FilterDocuments from "@/filter/Documents";



const routes = [

  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    children: [
      {
        path: '/signin/:resetId/:resetToken',
        name: "SigninReset",
        component: Signin,
      }
    ]
  },


  {
    path: "/tasks",
    name: "Tasks",
    components: {default: Tasks, filter: FilterTasks},
    children: [
      {
        path: '/tasks/:id',
        name: 'TaskDetails',
        component: Tasks,
        children: [{
          path: '/tasks/:id/:tab',
          name: 'TasksTabs',
          component: Tasks,
        }]
      }
    ]
  },
  {
    path: "/documents",
    name: "Documents",
    components: {default: Documents, filter: FilterDocuments},
    children: [
      {
        path: '/documents/:id',
        name: 'DocumentsDetails',
        component: Documents,
        children: [
          {
            path: '/documents/:id/:tab',
            name: 'DocumentsTabs',
            component: Documents,
            children: [{
              path: '/documents/:id/:tab/:subtab',
              name: 'DocumentsTabsSubtabs',
              component: Documents,
            }]
          }
        ]
      }
    ]

  },

  {path: '/:catchAll(.*)', component: Tasks}
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach( (to, from, next) => {

  store.commit("forceSWupdate");

  if (store.state.scope === undefined) {

     store.dispatch("checkToken").then(function () {

      if ((store.state.scope !== 'customer' && (store.state.user === undefined || !store.state.user.crm_customer_id)) && to.name !== 'Signin' && to.name !== 'SigninReset' && to.name !== 'IssuesWithoutLogin') {

        // set return url in local storage to redirect after login
	      console.log("skip2", to);
	      localStorage.setItem("return2Url", btoa(to.fullPath));
	      router.push("/signin");

      } else {
        let returnUrl =(to.query.returnUrl) ? atob(to.query.returnUrl) : '';
	      if (returnUrl && returnUrl !== '/') {
          next(returnUrl);
        }
        else {
          next();
        }

      }
    });
  } else {
    if ((store.state.scope !== 'customer' && (store.state.user === undefined || !store.state.user.crm_customer_id)) && to.name !== 'Signin' && to.name !== 'SigninReset' && to.name !== 'IssuesWithoutLogin') {

	    console.log("skip2", to);
      localStorage.setItem("return2Url", btoa(to.fullPath));
      router.push("/signin");

    } else {
      let returnUrl =(to.query.returnUrl) ? atob(to.query.returnUrl) : '';
      if (returnUrl && returnUrl !== '/') {
        next(returnUrl);
      }
      else {
        next();
      }

    }
  }


});
export default router;
