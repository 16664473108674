<template>
  <div class="modal fade show " tabindex="-1"
       aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("projects.newTask") }}</h5>
          <div class="btn-group" style="margin-left: auto;">
            <button type="button" class="btn btn-sm btn-outline-primary" @click="postTask">
              <i class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
            </button>

          </div>
          <button type="button" class="btn-close ms-3" @click="closeTask"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="projectTitle">{{ $t("globals.title") }}</label>
            <input type="text" class="form-control" id="projectTitle" v-model="newTaskItem.title">
          </div>
          <div class="form-group">
            <label for="projectDesc">{{ $t("globals.description") }}</label>
            <Editor2 v-model="newTaskItem.description"></Editor2>
          </div>

          <div class="row">
            <div class="col-6" v-if="projects.length">
              <label>{{ $t("globals.project") }}</label>
              <VueMultiselect class="small" :options="projects" v-model="newTaskItem.project" :multiple="false"
                              :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                              :placeholder="this.$t('projects.chooseProject')" label="title" :track-by="'id'"
                              :select-label="this.$t('projects.chooseProject')" :taggable="false"
              ></VueMultiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor2 from "@/components/Editor2.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  name: "Create",
  computed: {
    // ...mapGetters(["customerActive", "stateFilter", "projectFilter", "getEmployees"]),
    ...mapGetters(["customerActive", "getEmployees", "getMenu"]),
  },
  components: {Editor2, VueMultiselect},
  data() {
    return {
      newTaskItem: {
        title: "",
        description: "",
        start_date: new Date().toISOString().substr(0, 10),
        due_date: "",
        project: {},
        task_id: '',
        fillForDates: 0,
        customer: {
          id: '',
          hash: '',
          full_name: ''
        }
      },
      projects: []
    };
  },
  methods: {
    postTask() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let hash;
      if (this.customerActive && this.customerActive.hash) {
        hash = this.customerActive.hash;
      } else {
        hash = this.newTaskItem.customer.hash;
      }
      delete this.newTaskItem.customer;
      if (this.newTaskItem.employee_id) {
        this.newTaskItem.employee_id = this.newTaskItem.employee_id.id;
      }
      let postContent = {
        hash: hash,
        data: this.newTaskItem,
      }
      postContent.data.project_id = this.newTaskItem.project.id;
      // if (this.stateFilter && this.stateFilter.value) {
      //   postContent.data.state_id = this.stateFilter.value;
      // } else {
      postContent.data.state_id = 5;
      // }
      axios.post(this.$store.state.apiUrl + "issue/", postContent, {headers}).then((r) => {
        this.closeTask();
        this.$store.dispatch("tasksFilter");
        //this.$router.push({name: "tasks", params: {id: r.data.result.data.id}});
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
      });
    },
    closeTask() {
      this.$emit('close-task');
      this.$store.commit("closeModal", "task");
    },
    getProjects() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + this.newTaskItem.customer.id + "&filter_state=active", {headers}).then((r) => {
        this.projects = r.data.result.data;
      });
    }
  },
  created() {
    if (this.taskActive) {
      this.newTaskItem = this.taskActive;
    }
  },
  mounted() {
    if (this.customerActive) {
      this.getProjects();
    }
  },
  watch: {
    newTaskItem: {
      handler: function (val, oldVal) {
        if (val.customer && val.customer.id) {
          this.getProjects();
        }
      },
      deep: true
    }
  }
}
</script>
<style scoped>
</style>
