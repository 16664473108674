<template>
  <div class="taskLineWrap">
    <div class="d-flex">
      <div class="lineitem l-check">
        <button class="checkCircleBtn btn-outline-primary btn btn-sm" @click="checkTask(task)"><i
          class="fa-duotone fa-check fa-xl"></i></button>
      </div>
      <div class="lineitem l-title" @click="setTask(task, 'details')">
        {{ task.title }}
      </div>
      <div class="lineitem l-attachments" @click="setTask(task, 'attachments')">
        <template v-if="task._countAttachments > 0">
          <i class="fa-duotone fa-paperclip"></i><span> <span class="incNumber">{{ task._countAttachments }}</span></span>
        </template>
      </div>
      <div class="lineitem l-subtasks" @click="setTask(task, 'subtasks')" >

        <template v-if="task._countIssues > 0">
          <i class="fa-duotone fa-list-tree"></i><span> <span class="incNumber">{{ task._countIssues }}</span></span>
        </template>
      </div>
      <div class="lineitem l-comments"  @click="setTask(task, 'comments')">
        <template v-if="task._countComments > 0">
          <i class="fa-duotone fa-comment"></i><span> <span class="incNumber">{{ task._countComments }}</span></span>
        </template>
      </div>



      <div class="lineitem l-state"><span :tooltip="task.issueStateTitle" :position="'left'"
                                          :style="'color:'+task.issueStateColor"><i class="fa-duotone"
                                                                                    :class="task.issueStateIcon"></i></span>
      </div>

      <div class="lineitem l-hours">
        <template v-if="task.estimated_hours && task.estimated_hours > 0">
          <i class="fa-duotone fa-hourglass-end"></i> {{ task.estimated_hours }}
        </template>
      </div>
      <div class="lineitem l-date duedate"
           :class="(task.due_date < today) ? 'due' : (task.due_date === today) ? 'today' : ''"
           :tooltip="'Abgabedatum'" :position="'left'">{{ $filters.formatDate(task.due_date) }}
      </div>
      <div class="lineitem l-avatar ml-auto">
      <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, task.employee_id, 'id')" class="sketchAvatar" v-if="task.employee_id">
    </div>
      <div class="lineitem l-actions last">
        <template v-if="taskActive && taskActive.id === task.id">
          <i class="fa-duotone fa-x" @click="closeTask"></i>
        </template>
        <template v-else>
        <span @click="setTask(task)"><i class="fa-duotone fa-chevrons-up"></i>
        </span></template>
      </div>
    </div>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import router from "@/router";
import axios from "axios";

export default {
  name: "Headline",
  props: {
    task: Object,
  },
  computed: {
    ...mapGetters(["taskActive", "getEmployees", "customerActive"])
  },
  methods: {
    setTask(task, subview) {
      if (subview === undefined) {
        subview = "details";
      }
      // Unset if already set:
      if (this.taskActive === task.id) {
        // this.$store.dispatch('setTask', {})
        this.$router.push({name: "Tasks"});
      } else {
        this.$router.push({name: "TasksTabs", params: {id: task.id,tab: subview}})
      }

    },
    closeTask() {
      this.$store.dispatch("tasksFilter");
      this.$store.dispatch('setTask', null);
      this.$router.push({name: "Tasks"});
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    checkTask(task) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.task.customerHash;
      let postContent = {
        data: task,
        hash: hash
      }

      postContent.data.state_id = 2;
      axios.put(this.$store.state.apiUrl + 'issue/' + task.id, postContent, {headers})
        .then(response => {
          if (this.$route.params && this.$route.params.id) {
            this.$store.dispatch("tasksFilterCustomer");
          }
          else {
            this.$store.dispatch("tasksFilter");
          }
          if (task.parent_issue) {
            window.dispatchEvent(new CustomEvent('reloadSubtasks', {detail: task.parent_issue}));
          }
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    }
  },
  data() {
    return {
      today: new Date().toISOString().slice(0, 10),
    }
  }
}
</script>
